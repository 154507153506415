@use "sass:math";

// Custom sass mixins
// Define the custom mixins for your project here.
// http://sass-lang.com/docs/yardoc/file.SASS_REFERENCE.html#defining_a_mixin

////////////////////////////
// LINK STYLE
////////////////////////////

@mixin theme-link(
  $inactive-color: $link-color,
  $active-color: $link-color-hover,
  $hover-decoration: none,
  $focus-outline-color: $link-color-focus-outline
) {
  color: $inactive-color;
  border: 1px solid transparent;
  text-decoration: $link-text-decoration;
  background-color: transparent;

  &:hover {
    color: $active-color;
    text-decoration: none;
    outline: none;
    text-decoration: $hover-decoration;
  }

  &:focus {
    outline: 3px solid $focus-outline-color;
  }
}

////////////////////////////
// BUTTONS
////////////////////////////

@mixin external-link {
  &::after {
    content: $fa-external-link;
    font-family: $icon-font;
    font-size: 0.7em;
    position: relative;
    top: -0.125em;
    margin-left: 0.4em;
  }
}

@mixin button-base() {
  display: inline-block;
  padding: 6px 18px;
  border-radius: 0;
  position: relative;
  text-align: center;
  transition: all 0.2s ease;
  // Wrap long words
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
  text-decoration: none;
  box-shadow: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    outline: 4px solid $link-color-focus-outline;
    box-shadow: none;
  }
}

@mixin button(
  $color: $orange,
  $hover-bgrd-color: $orange-dark,
  $text-color: #ffffff
) {
  @include button-base;
  background: $color;
  color: $text-color;
  border: 1px solid $color;

  &:hover,
  &:focus {
    color: #ffffff;
    background: $hover-bgrd-color;
    border-color: $hover-bgrd-color;
  }
}

//////////////////////////////////////
// MISC MIXINS
//////////////////////////////////////

// Makes an element visually hidden, but accessible.
// @see http://snook.ca/archives/html_and_css/hiding-content-for-accessibility
@mixin element-invisible {
  position: absolute !important;
  height: 1px;
  width: 1px;
  overflow: hidden;
  clip: rect(1px, 1px, 1px, 1px);
}

// Turns off the element-invisible effect.
@mixin element-invisible-off {
  position: static !important;
  clip: auto;
  height: auto;
  width: auto;
  overflow: auto;
}

// Makes an element visually hidden by default, but visible when focused.
@mixin element-focusable {
  @include element-invisible;

  &:active,
  &:focus {
    @include element-invisible-off;
  }
}

// The word "Unpublished" displayed underneath unpublished nodes and comments.
@mixin unpublished-div {
  height: 0;
  overflow: visible;
  color: #d8d8d8;
  font-size: 75px;
  line-height: 1;
  font-family: Impact, "Arial Narrow", Helvetica, sans-serif;
  font-weight: bold;
  text-transform: uppercase;
  text-align: center;
  word-wrap: break-word; // A very nice CSS3 property
}

////////////////////////////
// DEBUG LAYOUT
////////////////////////////

// Adds outlines around elements to fix shotty layout.
@mixin debug-layout {
  * {
    outline: 2px dotted red;
  }

  * * {
    outline: 2px dotted green;
  }

  * * * {
    outline: 2px dotted orange;
  }

  * * * * {
    outline: 2px dotted blue;
  }

  * * * * * {
    outline: 1px solid red;
  }

  * * * * * * {
    outline: 1px solid green;
  }

  * * * * * * * {
    outline: 1px solid orange;
  }

  * * * * * * * * {
    outline: 1px solid blue;
  }
}

// @include debug-layout;


////////////////////////////
// TEXT OVERFLOW
////////////////////////////

@mixin text-overflow($method: ellipsis) {
  white-space: nowrap;
  overflow: hidden;
  -o-text-overflow: $method;
  text-overflow: $method;

  .ie & {
    width: 100%;
  }
}

////////////////////////////
// UNORDERED LISTS
////////////////////////////

@mixin no-resonate-bullets {
  padding-left: 0;

  &::before {
    content: none;
  }
}

@mixin no-bullets {
  li {
    list-style-type: none;
    list-style-image: none;
    @include no-resonate-bullets;
  }
}

@mixin clean-list {
  @include no-bullets;

  margin-left: 0;
  padding-left: 0;
}

@mixin horizontal-list($list-margin: 1em, $list-item-margin: 0.25em) {
  list-style-type: none;
  padding: 0;
  margin: $list-margin;
  overflow: hidden;

  > li {
    display: block;
    float: left;
    margin-right: $list-item-margin;

    &:last-child {
      margin-right: 0;
    }
  }
}

////////////////////////////
// CLEARFIX
////////////////////////////

@mixin clearfix {
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

////////////////////////////
// IMAGE REPLACEMENT
////////////////////////////

// Hide text of an element to replace by backgorund image
// Note: ffect pseudo-elements
@mixin hide-text() {
  border: 0;
  font: 0/0 a;
  text-shadow: none;
  color: transparent;
  background-color: transparent;
}

// MIMIC AN <hr>
@mixin divider {
  height: 1px;
  border: none;
  background: $horizontal-rule-border;
  padding: 0;
  @include responsive-gutter($property: margin-top, $multiplier: 2);
  @include responsive-gutter($property: margin-bottom, $multiplier: 2);
}

@mixin divider-top {
  border-top: 1px solid $horizontal-rule-border;
  @include responsive-gutter($property: padding-top, $multiplier: 1);
  @include responsive-gutter($property: margin-top, $multiplier: 1);
}

// MIMIC AN <hr>
@mixin divider-bottom {
  border-bottom: 1px solid $horizontal-rule-border;
  @include responsive-gutter($property: padding-bottom, $multiplier: 1);
  @include responsive-gutter($property: margin-bottom, $multiplier: 1);
}

@mixin icomoon-styling {
  font-family: $icon-font;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  // line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@mixin icomoon($icon, $position: "before") {
  &:#{$position} {
    @include icomoon-styling;

    @extend .icon-#{$icon};
  }
}

////////////////////////////
// PIXELS TO REM UNITS
////////////////////////////

// Baseline ($baseline-px) measured in pixels. The value should
// be the same as the font-size value for the html element.
// If the html element's font-size is set to 62.5%
// (of the browser's default font-size of 16px),
// then the variable below would be 10px.
// Example:
// .element {
//   @include rem('padding',10px 0 2px 5px);
// }

@mixin rem($property, $px-values, $baseline-px: $base-font-size) {
  // Convert the baseline into rems
  $baseline-rem: $baseline-px / 1rem * 1;
  // Print the first line in pixel values
  #{$property}: $px-values;

  // If there is only one (numeric) value, return the property/value line for it.
  @if type-of($px-values) == "number" {
    #{$property}: $px-values / $baseline-rem;
  } @else {
    // Create an empty list that we can dump values into
    $rem-values: ();

    @each $value in $px-values {
      // If the value is zero or not a number, return it
      @if $value==0 or type-of($value) != "number" {
        $rem-values: append($rem-values, $value);
      } @else {
        $rem-values: append($rem-values, $value / $baseline-rem);
      }
    }

    // Return the property and its list of converted values
    #{$property}: $rem-values;
  }
}

////////////////////////////
// TRIANGLES
////////////////////////////

// Used to create equilateral triangles
@mixin css-triangle($triangle-size, $triangle-color, $triangle-direction) {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border: inset $triangle-size;

  @if ($triangle-direction==top) {
    border-color: $triangle-color transparent transparent transparent;
    border-top-style: solid;
  }

  @if ($triangle-direction==bottom) {
    border-color: transparent transparent $triangle-color transparent;
    border-bottom-style: solid;
  }

  @if ($triangle-direction==left) {
    border-color: transparent transparent transparent $triangle-color;
    border-left-style: solid;
  }

  @if ($triangle-direction==right) {
    border-color: transparent $triangle-color transparent transparent;
    border-right-style: solid;
  }
}

/////////////////////////////
// INPUT PLACEHOLDER
/////////////////////////////

@mixin theme-placeholder(
  $color: $placeholder-color,
  $color-focus: $placeholder-focus-color
) {
  &::-webkit-input-placeholder {
    color: $color;

    &:focus {
      color: $color-focus;
    }
  }

  &:hover {
    &::-webkit-input-placeholder {
      color: $secondary-color;

      &:focus {
        color: $color-focus;
      }
    }
  }

  // Make disappear on focus in iOS... This works in Chrome as opposed to the above focus style
  &:focus::-webkit-input-placeholder {
    color: $color-focus;
  }

  &:-moz-placeholder {
    /* Firefox 18- */
    color: $color;

    &:focus {
      color: $color-focus;
    }
  }

  &::-moz-placeholder {
    /* Firefox 19+ */
    color: $color;

    &:focus {
      color: $color-focus;
    }
  }

  &:-ms-input-placeholder {
    color: $color;

    &:focus {
      color: $color-focus;
    }
  }
}

////////////////////////////
// RESPONSIVE
////////////////////////////

// Intrinsic Ratio Helper Function
// Used for responsive embed videos, etc...
@mixin intrinsicRatioContainer($width, $height) {
  height: 0;
  position: relative;
  padding-bottom: percentage(math.div($height, $width));
}

@mixin intrinsicRatioElement() {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  position: absolute;
}

////////////////////////////
// NON-RESPONSIVE LAYOUT
////////////////////////////

@mixin layout-gutter {
  padding-left: $gutter*0.5;
  padding-right: $gutter*0.5;
  padding-top: $gutter;
  padding-bottom: $gutter;
}

@mixin responsive-grid($columns: three) {
  @if ($columns==three) {
    @include breakpoint($tablet-portrait-min) {
      display: flex;
      flex-wrap: wrap;
      @include negative-margin;

      & > div {
        padding: $vert-gutter*0.5;
        width: 33.3333%;
      }
    }
  }
}

@mixin layout-container {
  margin-left: auto;
  margin-right: auto;
  max-width: $viewport-max;
  min-width: $viewport-min;
  width: 100%;
  @include layout-gutter;
}

@mixin grid-gutter {
  padding-left: ($gutter*0.5);
  padding-right: ($gutter*0.5);
}

@mixin grid-container {
  @include clearfix;
  margin-left: -$gutter*0.5;
  margin-right: -$gutter*0.5;
}
