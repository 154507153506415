
//////////////////////////////////////////////////////
// TYPOGRAPHY
/////////////////////////////////////////////////////

html {
  line-height: $body-line-height;
  scroll-behavior: smooth;
}

body {
  @include body-font-size;
  font-family: $body-font;
}

// ========================
//  HEADINGS

h1 {
  @include h1;
}

h2 {
  @include h2;
}

h3 {
  @include h3;
  color: $black
}

h4 {
  @include h4;
}

h2,
h4,
h5,
h6, {
  color: $page-title-color;
  font-family: $header-font;
}

// ========================
// LINKS

a {
  @include theme-link;
  cursor: pointer;
  transition: all $input-transition-duration ease-in-out;
}

// ========================
//  MISC

hr {
  @include divider;
}

blockquote {
  @include responsive-large-text;

  color: $orange-dark;
  margin: 0;
  @include responsive-gutter($property: margin-bottom, $multiplier: 1);
}

p,
ol,
ul,
dl,
menu {
  margin-top: 0;
  margin-left: 0;
  margin-right: 0;
  @include responsive-gutter($property: margin-bottom, $multiplier: 1);
}

// p:last-child {
//   margin-bottom: 0;
// }

li {
  @include responsive-gutter($property: margin-bottom, $multiplier: 0.25);
}

img {
  /* Remove border when inside `a` element in IE 8/9. */
  border: 0;

  /* Suppress the space beneath the baseline */
  vertical-align: bottom;

  /* Responsive images */
  max-width: 100%;
  height: auto;
}

figure {
  margin: 0;
  position: relative;

  figcaption {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    color: #ffffff;
    @include responsive-xsmall-text;
    padding: $gutter $half-gutter $half-gutter $half-gutter;
    text-shadow: 0 2px 4px #000000;
    background: linear-gradient(to bottom, transparent 0%, rgba(0,0,0,0.5) 40%);
  }
}

svg:not(:root) {
  overflow: hidden;
}

////////////////////////////////////////////////////
// BUTTONS
///////////////////////////////////////////////////

.button,
button,
input[type="submit"],
input[type="button"],
input[type="reset"] {
  @include button();
}
