.footer {
  background-color: $black;
  color: #ffffff;
  padding: $gutter 0;
  font-size: calculateRem(16px);

  h2, h3, h4 {
    color: #ffffff;
  }

  ul {
    margin: 0;
  }

  a {
    @include theme-link(
      $inactive-color:#ffffff,
      $active-color: #ffffff,
      $hover-decoration: underline
    );
  }
}

.footer-inner {
  @include layout-gutter;
  @include layout-container;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  // Mobile
  @media (max-width: 767px) {
    display: block;
    text-align: center;
  }

  .footer-sg-logo {
    flex: 0 1 40%;

    p {
      margin-top: $gutter * 0.5;
      margin-bottom: 0px;
    }
  }

  .footer-contact {
    flex: 0 1 60%;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-end;
    align-items: center;

    div {
      margin-left: $gutter;
    }

    img {
      max-width: 200px;
      height: auto;
    }

    // Tablet
    @media (max-width: 1024px) {
      div {
        margin-left: $gutter * 0.5;
      }
    }

    // Small Tablet
    @media (min-width: 768px) and (max-width: 1023px) {
      flex-wrap: wrap;
      flex-direction: column;
      justify-content: unset;
      align-items: unset;
      text-align: right;

      div {
        margin-left: 0px;
        margin-top: $gutter * 0.5;
      }
    }

    // Mobile
    @media (max-width: 767px) {
      display: block;
      margin-top: $gutter;

      div {
        margin-left: 0px;
      }
    }
  }
}

