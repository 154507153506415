@use "sass:math";

// /***************************/
// /* Helper functions */

@function strip-units($number) {
  @return (math.div($number, ($number * 0 + 1)));
}

@function calculateRem($size) {
  $remSize: math.div($size, 16px);
  @return #{$remSize}rem;
}

@function calculateClamp(
  $minimum,
  $preferred,
  $maximum,
  $preferred_viewport_width: 1170px
) {
  $clamp_parameter_1: calculateRem($minimum);
  $clamp_parameter_2: strip-units((math.div(($preferred + 0), $preferred_viewport_width)) * 100) * 1vw;
  $clamp_parameter_3: calculateRem($maximum);

  @return clamp(#{$clamp_parameter_1}, #{$clamp_parameter_2}, #{$clamp_parameter_3});
}

// /***************************/
// /* Utility mixins */

// /* The property will be at the preferrred size on 1170 pixel wide viewports by default. */
@mixin pixelsToResponsiveUnit(
  $property_name,
  $minimum,
  $preferred,
  $maximum,
  $preferred_viewport_width: 1170px
) {
  // /* Fallback for IE11 */
  #{$property_name}: calculateRem($preferred);
  #{$property_name}: calculateClamp($minimum, $preferred, $maximum, $preferred_viewport_width);
}

// All purpose gutter mixin
@mixin responsive-gutter(
  $property:margin-bottom,
  $multiplier:1,
  $mobile-gutter: $gutter-small,
  $tablet-gutter: $gutter-medium,
  $desktop-gutter: $gutter-large) {

    @include pixelsToResponsiveUnit(
      #{$property},
      $mobile-gutter * $multiplier,
      $tablet-gutter * $multiplier,
      $desktop-gutter * $multiplier
    );
}

// /***************************/
// /* Fluid gutters */

////////////////////////////////////////////////////////
// Pairs with 'responsive-grid-item'
// mixin for a Bootstrap style "negative margin" layout
@mixin responsive-grid-container(
  $multiplier:1,
  $mobile-gutter: $gutter-small,
  $tablet-gutter: $gutter-medium,
  $desktop-gutter: $gutter-large) {

  // Reverse order to deal with negative inputs
  @include pixelsToResponsiveUnit(
    margin-right,
    -(($desktop-gutter*0.5) * $multiplier),
    -(($tablet-gutter*0.5) * $multiplier),
    -(($mobile-gutter*0.5) * $multiplier)
  );

  // Reverse order to deal with negative inputs
  @include pixelsToResponsiveUnit(
    margin-left,
    -(($desktop-gutter*0.5) * $multiplier),
    -(($tablet-gutter*0.5) * $multiplier),
    -(($mobile-gutter*0.5) * $multiplier)
  );
}

////////////////////////////////////////////////////////
// Pairs with 'responsive-grid-container'
// mixin for a Bootstrap style "negative margin" layout
@mixin responsive-grid-item(
  $multiplier:1,
  $mobile-gutter: $gutter-small,
  $tablet-gutter: $gutter-medium,
  $desktop-gutter: $gutter-large) {

  @include pixelsToResponsiveUnit(
    padding-left,
    (($mobile-gutter*0.5) * $multiplier),
    (($tablet-gutter*0.5) * $multiplier),
    (($desktop-gutter*0.5) * $multiplier)
  );

  @include pixelsToResponsiveUnit(
    padding-right,
    (($mobile-gutter*0.5) * $multiplier),
    (($tablet-gutter*0.5) * $multiplier),
    (($desktop-gutter*0.5) * $multiplier)
   );
}

// /***************************/
// /* Fluid text */

// $body-font-size-large: 22px;
// $body-font-size-medium: 18px; // Desktop
// $body-font-size-small: 16px; // Mobile
// $body-font-size-xsmall: 14px; // Small text on desktop
// $body-font-size-xxsmall: 12px; // xsmall on desktop

@mixin responsive-xsmall-text {
  @include pixelsToResponsiveUnit(font-size, $body-font-size-xxsmall, $body-font-size-xsmall, $body-font-size-xsmall); // 12px-14px
}

@mixin responsive-small-text {
  @include pixelsToResponsiveUnit(font-size, $body-font-size-xsmall, $body-font-size-small, $body-font-size-medium); // 14px, 16px, 18px
}

@mixin responsive-medium-text {
  @include pixelsToResponsiveUnit(font-size, $body-font-size-xsmall, $body-font-size-small, $body-font-size-small); // 14px, 16px
}

@mixin responsive-large-text {
  @include pixelsToResponsiveUnit(font-size, 19px, $body-font-size-large, $body-font-size-large);
}

@mixin responsive-xlarge-text() {
  @include pixelsToResponsiveUnit(font-size, 24px, 26px, 28px);
}

// Example use
// Use (@include h1;) to match font size, margin and line height.
@mixin h1 {
  @include pixelsToResponsiveUnit(font-size, $h1-font-size-mobile, $h1-font-size, $h1-font-size);
  line-height: $h1-line-height;
  @include responsive-gutter($property:margin-top, $multiplier:1);
  @include responsive-gutter($property:margin-bottom, $multiplier:1);
}

@mixin h2 {
  @include pixelsToResponsiveUnit(font-size, $h2-font-size-mobile, $h2-font-size, $h2-font-size);
  line-height: $h2-line-height;
  margin-top: 0;
  font-weight: 700;
  // @include responsive-gutter($property:margin-top, $multiplier:1);
  @include responsive-gutter($property:margin-bottom, $multiplier:1);
}

@mixin h3 {
  @include pixelsToResponsiveUnit(font-size, $h3-font-size-mobile, $h3-font-size, $h3-font-size);
  line-height: $h2-line-height;
  margin-top: 0;
  font-weight: 600;
  // @include responsive-gutter($property:margin-top, $multiplier:0.5);
  @include responsive-gutter($property:margin-bottom, $multiplier:0.5);
}

@mixin h4 {
  @include pixelsToResponsiveUnit(font-size, $h4-font-size-mobile, $h4-font-size, $h4-font-size);
  line-height: $h4-line-height;
  @include responsive-gutter($property:margin-top, $multiplier:0.5);
  @include responsive-gutter($property:margin-bottom, $multiplier:0.5);
}

@mixin body-font-size() {
  @include pixelsToResponsiveUnit(font-size, $body-font-size-small, $body-font-size-medium, $body-font-size-medium);
}

@mixin label {
  display: block;
  font-weight: bold;
  font-size: 1rem; // 16px
  margin-bottom: 0.5rem;
}
