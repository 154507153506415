// Generic section styling
.section-videos,
.section-images,
.section-specs,
.section-cta {
  position: relative;
  overflow: hidden;

  h2 {
    position: relative;
    text-align: center;
    z-index: 1;
  }
}

// Slick slider styles
.carousel,
.videos {
  button {
    &.slick-arrow {
      height: auto;

      i {
        font-size: 2rem;

        &::before {
          color: $gray-dark;
        }
      }

      &:hover,
      &:focus {
        background: transparent;
        border-color: transparent;
        outline: none;

        i {
          &::before {
            color: $black;
          }
        }
      }
    }

    &.slick-prev {
      z-index: 5;
      left: -$gutter;

      @media (max-width: 1400px) {
        left: -$gutter * 0.25;
      }
    }

    &.slick-next {
      z-index: 5;
      right: -$gutter;

      @media (max-width: 1400px) {
        right: -$gutter * 0.25;
      }
    }
  }

  .slick-dots {
    bottom: -$gutter * 1.25;

    button {
      .slick-dot-icon {
        color: transparent;
        opacity: 0.5;

        &::before {
          font-size: 18px;
          border: 2px solid $gray-dark;
          border-radius: 50%;
        }
      }

      &:focus {
        outline: none;

        .slick-dot-icon {
          &::before {
            color: transparent;
            border: 2px solid $gray-dark;
          }
        }
      }
    }

    .slick-active {
      .slick-dot-icon {
        color: transparent;

        &::before {
          background: $gray-dark;
          margin-top: unset;
          margin-left: unset;
          font-size: 18px;
          border: 2px solid $gray-dark;
          border-radius: 50%;
        }
      }
    }

    @media (max-width: 767px) {
      bottom: -$gutter * 1.75;
    }
  }
}

.carousel {
  z-index: 3;

  .slick-track {
    display: flex !important;
  }

  .slick-slide {
    height: inherit !important;
  }

  .slick-slide > div {
    background-color: $white;
    color: $black;
    border: 1px solid $gray-medium;
    margin-right: $gutter * 0.5;
    margin-left: $gutter * 0.5;
    box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
    height: 100%;

    .slide-image {
      min-height: 200px;
      display: flex;
      justify-content: center;
      align-items: stretch;
      flex-direction: column;

      @media (max-width: 767px) {
        min-height: 150px;
      }

      @media (min-width: 768px) and (max-width: 1024px) {
        min-height: 100px;
      }

      img {
        width: 100%;
        margin: 0 auto;
      }
    }

    .slide-text {
      border-top: 1px solid $gray-medium;
      padding: $gutter * 0.5;
    }
  }
}

// Videos
.videos {
  display: flex;
  flex-wrap: wrap;
  @include no-bullets;
  padding: 0;
  margin: 0;

  li {
    flex: 1 1 45%;
    margin-bottom: $gutter;

    h3 {
      margin-top: $gutter * 0.5;
      text-align: center;
      color: $black;
    }

    // Desktop
    @media (min-width: 768px) {
      &:nth-of-type(odd) {
        margin-right: $gutter * 0.5;
      }

      &:nth-of-type(even) {
        margin-left: $gutter * 0.5;
      }
    }

    // Mobile
    @media (max-width: 767px) {
      flex: 1 1 100%;
      margin-bottom: 0px;

      .responsive-video {
        margin: 0 18px;
      }
    }
  }
}

// Product Images
.section-images {
  position: relative;
  overflow: visible;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    height: 100%;
    width: 100vw;
    background-color: $gray-lightest;
    z-index: 0;
  }

  &::after {
    content: "";
    background: url('assets/images/sgtools-left-blue.svg');
    background-repeat: no-repeat;
    background-size: auto;
    position: absolute;
    bottom: 0;
    left: -20rem;
    height: 80%;
    width: 100%;
    z-index: 1;
  }
}

// Body text sections
.intro,
.spec-intro {
  text-align: center;
}

// CTA section
.section-cta {
  background: $blue;
  top: 115px;

  .cta {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;

    h2 {
      color: $white;
      margin: 0px;
    }

    a {
      text-transform: uppercase;
      font-weight: 600;

      &:focus {
        outline: none;
      }
    }

    @media (max-width: 767px) {
      display: block;
      text-align: center;

      h2 {
        margin-bottom: $gutter;
      }
    }
  }
}

.section-cta-button {
  position: sticky;
  bottom: 0;
  z-index: 9999;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;

  a {
    text-transform: uppercase;
    font-weight: 600;
    pointer-events: auto;
  }

  @media (max-width: 767px) {
    position: relative;
    z-index: auto;
    justify-content: center;
    margin-top: $gutter;
    background: $blue;
  }
}

// Products table styles
.section-specs {
  .section-inner {
    position: relative;
    z-index: 3;
  }

  &::after {
    content: "";
    background: url('assets/images/sgtools-right-blue.svg');
    background-repeat: no-repeat;
    background-size: auto;
    position: absolute;
    bottom: -40rem;
    right: 0;
    height: 100%;
    width: 30%;
    z-index: 1;

    @media (max-width: 767px) {
      content: unset;
    }
  }

  .product-table {
    display: flex;

    .product-tabs-menu {
      flex: 0 1 20%;
      padding-right: $gutter;

      h3,
      li {
        padding-bottom: $gutter * 0.25;
        border-bottom: 1px solid $gray-medium;
      }

      ul {
        padding-left: 0px;
        @include no-bullets;

        li {
          &:hover,
          &:focus {
            color: $blue;
            cursor: pointer;
          }
        }
      }
    }

    .product-tabs {
      flex: 0 1 80%;

      .table-bands,
      .table-wedges,
      .table-complete {
        display: none;
      }

      table {
        table-layout: fixed;
        width: 100%;
        border-collapse: collapse;
        border: 1px solid $gray-medium;

        thead {
          background: $gray-medium;

          th {
            padding: 5px;
          }
        }

        tbody {
          tr {
            &:nth-of-type(even) {
              background: $gray-lightest;
            }

            &:nth-of-type(odd) {
              background: $white;
            }

            td {
              border-right: 1px solid $gray-medium;
              padding: $gutter * 0.25;
            }

            &:first-child {
              td {
                padding: 0px;
              }
            }
          }

          img {
            width: 100%;
            height: auto;
            aspect-ratio: auto;
            background-color: transparent;
          }
        }
      }
    }

    // Mobile
    @media (max-width: 767px) {
      display: block;

      .product-tabs-menu {
        flex: unset;
        padding-right: 0px;
        border-top: 1px solid $gray-medium;
        position: relative;

        h3 {
          padding-top: $gutter * 0.25;
          position: relative;
          z-index: 2;

          &:hover {
            cursor: pointer;
          }
        }

        ul {
          display: none;
        }

        &::after {
          content: "\f078";
          font-family: "Fontawesome";
          color: $blue;
          font-size: 1.5rem;
          position: absolute;
          top: 2px;
          right: 0;
          z-index: 1;
        }

        &.product-menu-open {
          &::after {
            content: "\f077";
          }
        }
      }

      .product-tabs {
        flex: unset;
        margin-top: $gutter * 0.5;

        & > div {
          overflow-x: auto;
        }

        table {
          width: unset;
        }
      }
    }
  }
}
