.element-invisible {
  @include element-invisible;
}

////////// VIDEOS

.responsive-video {
  @include intrinsicRatioContainer(560px, 315px);

  iframe {
    @include intrinsicRatioElement();
  }
}
