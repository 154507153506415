// Regular
@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/woff2/AvenirNext-Regular-08.woff2") format("woff2"),
       url("../fonts/woff/AvenirNext-Regular-08.woff") format("woff"),
       url("../fonts/ttf/AvenirNext-Regular-08.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

// Italic
@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/woff2/AvenirNext-Italic-05.woff2") format("woff2"),
       url("../fonts/woff/AvenirNext-Italic-05.woff") format("woff"),
       url("../fonts/ttf/AvenirNext-Italic-05.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

// Demi Bold
@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/woff2/AvenirNext-DemiBold-03.woff2") format("woff2"),
       url("../fonts/woff/AvenirNext-DemiBold-03.woff") format("woff"),
       url("../fonts/ttf/AvenirNext-DemiBold-03.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

// Demi Bold Italic
@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/woff2/AvenirNext-DemiBoldItalic-04.woff2") format("woff2"),
       url("../fonts/woff/AvenirNext-DemiBoldItalic-04.woff") format("woff"),
       url("../fonts/ttf/AvenirNext-DemiBoldItalic-04.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

// Bold
@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/woff2/AvenirNext-Bold-01.woff2") format("woff2"),
       url("../fonts/woff/AvenirNext-Bold-01.woff") format("woff"),
       url("../fonts/ttf/AvenirNext-Bold-01.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

// Bold Italic
@font-face {
  font-family: "Avenir Next";
  src: url("../fonts/woff2/AvenirNext-BoldItalic-02.woff2") format("woff2"),
       url("../fonts/woff/AvenirNext-BoldItalic-02.woff") format("woff"),
       url("../fonts/ttf/AvenirNext-BoldItalic-02.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}
