// Language Menu
.languages-container {
  position: relative;
  top: -$gutter;
  left: 0;
  right: 0;
  bottom: 0;

  @media (max-width: 767px) {
    display: flex;
    justify-content: center;
  }

  .languages-nav {
    .ms-dd {
      width: $gutter * 5;
      background: $black;
      border: 1px solid $black;
      border-bottom-left-radius: $gutter * 0.25;
      border-bottom-right-radius: $gutter * 0.25;

      .ms-dd-label {
        font-weight: normal;
        font-family: $font-sans-serif;
      }

      .ms-dd-list-option{
        border: none;
      }

      .ms-dd-pointer-down {
        border-top-color: $white;
      }

      .ms-dd-pointer-up {
        border-bottom-color: $white;
      }

      img {
        max-width: 24px;
      }

      .ms-dd-header {
        border: 1px solid $black;
        border-radius: 0px;
        border-bottom-left-radius: $gutter * 0.25;
        border-bottom-right-radius: $gutter * 0.25;

        a {
          background: $black;
          color: $white;
          border-bottom: none;

          span {
            color: $white;
          }
        }
      }

      .ms-options {
        background: $black;
        box-shadow: none;
        border: 1px solid $black;
        border-bottom-left-radius: $gutter * 0.25;
        border-bottom-right-radius: $gutter * 0.25;

        .ms-list-option {
          border: none;
          background: $black;
          color: $white;
          margin-bottom: 0px;

          &:last-child {
            margin-bottom: 0px;
          }

          &.option-selected {
            background: lighten($black, 15%);
          }

          &:hover,
          &:focus {
            background: lighten($black, 15%);
            color: $white;
            text-decoration: underline;
          }
        }
      }
    }
  }
}


