body,
html {
  margin: 0;
  padding: 0;
  height: 100%;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

.l-container {
  @include layout-gutter;
  @include layout-container;
  margin-left: auto;
  margin-right: auto;

  &.narrow {
    max-width: $content-max-width;
  }
}
