//////////////////////////////////////////
// LAYOUT
/////////////////////////////////////////

// Fixed gutter
$gutter: 36px;
$half-gutter: $gutter*0.5;
$quarter-gutter: $gutter*0.25;

// Responsive gutters
$gutter-small: $half-gutter;
$gutter-medium: $gutter*0.75;
$gutter-large: $gutter;

// Set min and max width of the overall layout
$viewport-min: 320px;
$viewport-max: 1164px + $gutter;

// Converted to ems for better font scaling & accessibility.
// Forced base font size of 16px in second argument to prevent unexpected calculations for breakpoints.
$breakpoint-xsmall-max: "max-width: 29.9375em"; // em(479px, 16px);
$breakpoint-xsmall-min: "min-width: 30em"; // em(480px, 16px);

$breakpoint-small-max: "max-width: 37.4375em"; // em(599px, 16px);
$breakpoint-small-min: "min-width: 37.5em"; // em(600px, 16px); // Typically start of 2 column

$breakpoint-medium-max: "max-width: 47.4375em"; // em(759px, 16px);
$breakpoint-medium-min: "min-width: 47.5em"; // em(760px, 16px); // iPad portrait (landscape is 1024px)

$breakpoint-large-max: "max-width: 52.4375em"; // em(839px, 16px);
$breakpoint-large-min: "min-width: 52.5em"; // em(840px, 16px); // Typically start of 3 column

$breakpoint-xlarge-max: "max-width: 67.4375em"; // em(1079px, 16px);
$breakpoint-xlarge-min: "min-width: 67.5em"; // em(1080px, 16px); // Typically start of 4 column

$breakpoint-wide-images-min: "min-width: 60em"; // 960px, point when images overlap sides
$breakpoint-wide-images-min: "min-width: 59.9375em"; // 960px, point when images overlap sides

$content-max-width: 768px;

//////////////////////////////////////////
// THEME
/////////////////////////////////////////

// =====================
// COLOR PALLETE

// ...
$orange-light: lighten(#DE5935, 15%);
$orange: #DE5935;
$orange-dark: darken(#DE5935, 15%);
$blue: #025687;
$gray-dark: #7C888E;
$gray-medium: #D2D2D2;
$gray-light: #D9D9D9; // dividing borders
$gray-lightest: #F2F2F2;
$white: #FFFFFF;
$black: #000000;

// ====================
// TYPOGRAPHY

$base-font-size: 10px; // The font size set on the root html element & used in rem() mixin calculations

$h1-font-size: 54px;
$h1-font-size-mobile: 36px;
$h1-line-height: 1.2;

$h2-font-size: 36px;
$h2-font-size-mobile: 30px;
$h2-line-height: 1.2;

$h3-font-size: 28px;
$h3-font-size-mobile: 18px;
$h3-line-height: 1.2;

$h4-font-size: 22px;
$h4-font-size-mobile: 18px;
$h4-line-height: 1.2;

$base-line-height: $base-font-size * 1.675;
$body-line-height: 1.55;

// Used in responsive text mixins
$body-font-size-large: 22px; // Large text in desktop
$body-font-size-medium: 18px; // Desktop
$body-font-size-small: 16px; // Mobile
$body-font-size-xsmall: 14px; // Small text on desktop
$body-font-size-xxsmall: 12px; // xsmall on desktop

// Common font stacks
$font-sans-serif: 'Avenir Next', 'Open Sans', Helvetica, sans-serif;
$font-serif: Times, Georgia, serif;

// TEXT
$header-font: $font-sans-serif;
$body-font: $font-sans-serif;
$page-title-color: $blue;
$body-font-color: $gray-dark;

// =====================
// ELEMENTS

// LINKS
$link-color: $orange-dark;
$link-color-visited: $orange-dark;
$link-color-hover: $orange-dark;
$link-color-focus: $orange-dark;
$link-color-focus-border: $orange-dark;
$link-color-focus-outline: $orange;
$link-color-active: $link-color-visited;
$link-text-decoration: underline;

// HR
$horizontal-rule-border: $gray-dark;

// ======================
// ANIMATIONS

$input-transition-duration: 0.2s;
