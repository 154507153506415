.header {
  background-color: $blue;
  background-image: url('assets/images/sgtools.png');
  background-repeat: no-repeat;
  background-size: cover;
  height: 50vh;
  position: relative;

  @media (max-width: 767px) {
    height: 40vh;
  }
}

.header-logos-container {
  position: absolute;
  bottom: 15%;
  color: $white;

  @media (max-width: 767px) {
    text-align: center;
  }

  .header-logos-inner {
    picture {
      display: block;

      img {
        width: 40%;

        @media (max-width: 767px) {
          width: 80%;
        }
      }
    }

    .logo-secondary {
      padding-top: $gutter * 0.5;

      picture {
        display: inline-block;
        max-width: 30%;

        @media (max-width: 767px) {
          margin-left: -10px;
        }
      }

      span {
        display: inline-block;
      }
    }
  }
}
